import https from '@/router/https'

//订单管理
export function getorderManagement(param) {
  return https.fetchGet('/mallapp/orderinfo/page', 
  param);
}

//取消订单
export function cancelOrder(id) {
  return https.fetchPut('/mallapp/orderinfo/cancel/'+id);
}