<template>
  <div>
    <!-- 导航 -->
    <div  style="position:fixed;top:0;left:0;right: 0; right: 0px; z-index: 10;">
      <van-nav-bar left-arrow @click-left="Close" :fixed="true">
        <div slot="title"  class="orderManagent" style="
  border-radius: 50rem;
  background-color: rgba(241, 241, 241, 1);
  width: 152px;
  justify-content: center;
 align-items: center;display: flex">
  <div   :class="isShowgoods== true?'div-leftafter':'div-left'"   @click="ClickOrder(0)" >我的订单</div>
  <div  :class="isShowgoods== false?'div-rightafter':'div-right'"    @click="ClickOrder(1)">粉丝订单</div>
   </div>
      </van-nav-bar>
    </div>
    <!-- 订单信息 -->
    <div  style="margin:40px 0;">
      <div v-if="orderType === 1">
        <fansOrderNew />
      </div>
      <div v-else-if="orderType === 0">
        <myOrder />
      </div>
    </div>
    <!-- 订单信息 -->
  </div>
</template>
<script>
import myOrder from '@/views/myOrder/index.vue'
import fansOrderNew from '@/views/fansOrderNew/index.vue'
// import { getorderManagement } from  '@/api/orderManagement/orderManagement'
export default {
  name: "orders",
  data() {
    return {
        orderType:0,
        isShowgoods:true,
         phoneType:'isAndroid',
        // size:3,
        // current:2,
        // myShopStatus:''
    };
  },
  created() {
      if(this.$route.query.session!=null)
      {
        this.session=this.$route.query.session
        this.$store.commit('setSession', this.session)
      }
     this.phoneType=this.getPhoneType()
  },
  components: {
      myOrder,
  fansOrderNew},
  methods:{
      ClickOrder(int){
         this.orderType=int
          if(int==0)
       {
         this.isShowgoods=true;
       }else{
         this.isShowgoods=false;
       }
      },
        getPhoneType()
    {
       let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
         if(isIOS)
        {
          return 'isIOS'
        }
        if (isAndroid) {
         return 'isAndroid'
        } 
     },
      // 返回原生
    Close()
    {
     
      if(this.phoneType=='isIOS')
      {
          window.webkit.messageHandlers.close.postMessage('up');   
      }
      if (this.phoneType=='isAndroid') {
            window.android.close(); 
       } 
      this.$store.commit('setActive',-1)
     },
    // getorderManagementtype(){
    //   let params = {myShopStatus:this.myShopStatus}
    //   getorderManagement(params).then(res =>{
    //     console.log('5',res)
    //   })
    // }
  }
};
</script>
<style scoped>
.van-card {
  position: relative;
  box-sizing: border-box;
  padding: 8px 16px;
  color: #323233;
  font-size: 12px;
  background-color: hsl(0deg 0% 100% / 90%);
}
.orderManagent .div-left
{
  border-radius:  50rem;
  background-color: rgba(241, 241, 241, 1);
  width: 76px;
  justify-content: center;
   align-items:center;float:left;font-size:14px;
  color: rgba(51, 51, 51, 1);
}
.orderManagent .div-right
{
  border-radius: 50rem;
  background-color: rgba(241, 241, 241, 1);
  width: 76px;
  justify-content: center;
  align-items:center;
  float:right;font-size:14px;
  color: rgba(51, 51, 51, 1);
}
.orderManagent .div-leftafter
{
  border-radius: 50rem;
  background: -webkit-linear-gradient(left, #FF7200FF, #FF3C00FF);
  width: 76px;
  justify-content: center;
  align-items:center;float:left;font-size:14px;
  color: white;
}
.orderManagent .div-rightafter
{

  border-radius: 50rem;
  background: -webkit-linear-gradient(left, #FF7200FF, #FF3C00FF);
  width: 76px;
  justify-content: center;
  align-items:center;float:right;font-size:14px;
  color: white;
}
</style>
