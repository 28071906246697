<template>
  <div>
    <!-- 导航 -->
    <div  style="margin:40px 0;">
      <van-tabs swipeable @change="onChange" :active="active"  offset-top="46px"  :sticky="true">
        <van-tab title="全部" >
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
        <div>
          <div
           class="order-div"
            v-for="(item,index) in  getfansOrderlister"
            :key="index"
          >
            <div>
              <!--店铺名--->
              <van-cell>
                <van-col span="14">{{item.shopInfo!=null?item.shopInfo.name:item.shopName}}</van-col>
                <van-col span="8"  offset="2" class="status-word" >{{item.statusDesc}}</van-col>
              </van-cell>
           </div>
            <!---商品信息--->
            <div v-for="(order,count) in  item.listOrderItem"
            :key="count" @click="toDetail(item)">
              <van-card :num="order.quantity"  :thumb="order.picUrl">
                <div slot="title">
                  <van-col span="16">
                    <span>{{order.spuName}}</span>
                  </van-col>
                  <van-col span="8" style="text-align: right;">
                    <span class="info3-order">¥{{order.salesPrice}}</span>
                  </van-col>

                </div>
                <div slot="price" v-if="order.specInfo!=null">
                  <span class="info-goods">规格：{{order.specInfo.replace(',',' ')}}</span>
                </div>
                <div slot="desc">
                </div>
                  <!--- 商品数量--->
             <div slot="footer">
              <div style="height:40px;margin-top:10px; "> 
                    <span class="info3-order" style="float:right">¥{{order.paymentPrice}}</span>
                    <span style="font-size:18px;padding-left:25px;float:right " >合计：</span>
                    <span style="font-size:18px;float:right">共{{order.quantity}}件商品 </span>
              </div>
             </div>
              </van-card>
            </div>
            <!--操作--->
              <div v-if="item.type=='4'&& item.isPay=='0'&&item.status==null">
              <van-cell >
                  <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px; float:right;margin-left:10px" @click="OpenPay(item)"
                  >付款</van-button>
                 <van-button plain style="border-radius: 20px; height:28px;width:94px; float:right"  @click="cancelOrder(item)"> 取消订单</van-button>
                
              </van-cell>
            </div>

          <div v-if="item.type=='4'&&item.status=='1'">
              <van-cell >
                <van-col span="7" offset="17">
                  <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px; float:right" @click="$toast('提醒成功')">提醒发货</van-button>
                </van-col>
              </van-cell>
            </div>

          <div v-else-if="item.type=='4'&&item.status=='2'">
              <van-cell >
                      
                  <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px; float:right" @click="receiveOrder(item)">确认收货</van-button>
                     <van-button
                    style="border-radius: 20px; height:28px; float:right">查看物流</van-button>
                  <van-button
                    style="border-radius: 20px; height:28px; float:right">延长收货</van-button>      
         
              </van-cell>
            </div>


          <div v-else-if="item.type=='4'&&item.status=='3'&& item.appraisesStatus == '1'">
              <van-cell >
                      <!-- <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px;float:right" @click="byGoods(item)">再次购买</van-button> -->
                <van-button
                    plain
                    style="border-radius: 20px; height:28px;float:right">退款/售后</van-button>
            
          
              </van-cell>
            </div>

             <div v-else-if="item.type=='4'&&item.status=='3'&& item.appraisesStatus=='0'">
              <van-cell >
                      <van-button
                     color="#FF3C00"
                     style="border-radius: 20px; height:28px;float:right"  @click="OpenComment(item)" >评价</van-button>
              </van-cell>
            </div>
            <div v-else-if="item.status=='5'">
            </div>
            <!----->
          </div>
          </div>
       </van-list>
            </van-pull-refresh>
        </van-tab>
        <van-tab title="待付款">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
        <div>
          <div
           class="order-div"
            v-for="(item,index) in  getfansOrderlister"
            :key="index"
          >
            <div  v-if="item.status==null">
              <!--店铺名--->
              <van-cell>
                <van-col span="14">{{item.shopInfo!=null?item.shopInfo.name:item.shopName}}</van-col>
                <van-col span="8" offset="2" class="status-word">{{item.statusDesc}}</van-col>
              </van-cell>
           </div>
            <!---商品信息--->
            <div  v-for="(order,count) in  item.listOrderItem"
            :key="count" @click="toDetail(item)">
              <van-card :num="order.quantity"  :thumb="order.picUrl">
                <div slot="title">
                  <van-col span="16">
                    <span>{{order.spuName}}</span>
                  </van-col>
                  <van-col span="8" style="text-align: right;">
                    <span class="info3-order">¥{{order.salesPrice}}</span>
                  </van-col>
                </div>
                <div slot="price" v-if="order.specInfo!=null">
                  <span class="info-goods">规格：{{order.specInfo.replace(',',' ')}}</span>
                </div>
                <div slot="desc">
                </div>
                  <!--- 商品数量--->
             <div slot="footer">
              <div style="height:40px;margin-top:10px; "> 
                    <span class="info3-order" style="float:right">¥{{order.paymentPrice}}</span>
                    <span style="font-size:18px;padding-left:25px;float:right " >合计：</span>
                    <span style="font-size:18px;float:right">共{{order.quantity}}件商品 </span>
              </div>
             </div>
              </van-card>
            </div>
            <!--操作--->
               <div v-if="item.type=='4'&& item.isPay=='0'&& item.status==null">
              <van-cell >
                  <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px; float:right;margin-left:10px" @click="OpenPay(item)"
                  >付款</van-button>
                 <van-button plain style="border-radius: 20px; height:28px;width:94px; float:right"  @click="cancelOrder(item)"> 取消订单</van-button>
                
               
              </van-cell>
            </div>
                    </div>
            <!----->

          </div>
       </van-list>
          </van-pull-refresh>
        </van-tab>

       <van-tab title="待发货">
       <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
        <div>
          <div
           class="order-div"
            v-for="(item,index) in  getfansOrderlister"
            :key="index"
          >
            <div>
              <!--店铺名--->
              <van-cell>
                <van-col span="14">{{item.shopInfo!=null?item.shopInfo.name:item.shopName}}</van-col>
                <van-col span="8" offset="2" class="status-word">{{item.statusDesc}}</van-col>
              </van-cell>
           </div>
            <!---商品信息--->
             <div v-for="(order,count) in  item.listOrderItem"
            :key="count">
            <div  @click="toDetail(item)">
              <van-card :num="order.quantity"  :thumb="order.picUrl">
                <div slot="title">
                  <van-col span="16">
                    <span>{{order.spuName}}</span>
                  </van-col>
                  <van-col span="8" style="text-align: right;">
                    <span class="info3-order">¥{{order.salesPrice}}</span>
                  </van-col>

                </div>
                <div slot="price" v-if="order.specInfo!=null">
                  <span class="info-goods">规格：{{order.specInfo.replace(',',' ')}}</span>
                </div>
                <div slot="desc">
                </div>
                  <!--- 商品数量--->
             <div slot="footer">
              <div style="height:40px;margin-top:10px; "> 
                    <span class="info3-order" style="float:right">¥{{order.paymentPrice}}</span>
                    <span style="font-size:18px;padding-left:25px;float:right " >合计：</span>
                    <span style="font-size:18px;float:right">共{{order.quantity}}件商品 </span>
              </div>
             </div>
              </van-card>
            </div>
            <!--操作--->
              <div v-if="order.type==null">
               <van-cell >
                <van-col span="7" offset="17">
                  <van-button
                     color="#FF3C00"
                    style="border-radius: 20px; height:28px;float:right" @click="$toast('提醒成功')">提醒发货</van-button>
                </van-col>
              </van-cell>
            </div>
            </div>
            <!----->
          </div>
          </div>
       </van-list>
       </van-pull-refresh>
        </van-tab>
       <van-tab title="待收货">
       <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
        <div>
          <div
           class="order-div"
            v-for="(item,index) in  getfansOrderlister"
            :key="index"
          >
            <div>
              <!--店铺名--->
              <van-cell>
                 <van-col span="14">{{item.shopInfo!=null?item.shopInfo.name:item.shopName}}</van-col>
                <van-col span="8" offset="2" class="status-word">{{item.statusDesc}}</van-col>
              </van-cell>
           </div>
            <!---商品信息--->
             <div v-for="(order,count) in  item.listOrderItem"
            :key="count" >
            <div @click="toDetail(item)" >
              <van-card :num="order.quantity"  :thumb="order.picUrl">
                <div slot="title">
                  <van-col span="16">
                    <span>{{order.spuName}}</span>
                  </van-col>
                  <van-col span="8" style="text-align: right;">
                    <span class="info3-order">¥{{order.salesPrice}}</span>
                  </van-col>

                </div>
                <div slot="price" v-if="order.specInfo!=null">
                  <span class="info-goods">规格：{{order.specInfo.replace(',',' ')}}</span>
                </div>
                <div slot="desc">
                </div>
                  <!--- 商品数量--->
             <div slot="footer">
              <div style="height:40px;margin-top:10px; "> 
                    <span class="info3-order" style="float:right">¥{{order.paymentPrice}}</span>
                    <span style="font-size:18px;padding-left:25px;float:right " >合计：</span>
                    <span style="font-size:18px;float:right">共{{order.quantity}}件商品 </span>
              </div>
             </div>
              </van-card>
            </div>
            <!--操作--->
              <div>
              <van-cell >
                  <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px; float:right" @click="receiveOrder(item)">确认收货</van-button>
                  <van-button
                    style="border-radius: 20px; height:28px; float:right">查看物流</van-button>
                  <van-button
                    style="border-radius: 20px; height:28px; float:right" >延长收货</van-button>     
              </van-cell>
            </div>
            </div>
            <!----->
          </div>
          </div>
       </van-list>
       </van-pull-refresh>
        </van-tab>
        <van-tab title="已完成">
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
        <div>
          <div
           class="order-div"
            v-for="(item,index) in  getfansOrderlister"
            :key="index"
          >
            <div>
              <!--店铺名--->
              <van-cell>
                <van-col span="16">{{item.shopInfo!=null?item.shopInfo.name:item.shopName}}</van-col>
                <van-col span="8"  class="status-word">{{item.statusDesc}}</van-col>
              </van-cell>
           </div>
            <!---商品信息--->
               <div v-for="(order,count) in  item.listOrderItem"
            :key="count" >
             <div @click="toDetail(item)">
              <van-card :num="order.quantity"  :thumb="order.picUrl">
                <div slot="title">
                  <van-col span="16">
                    <span>{{order.spuName}}</span>
                  </van-col>
                  <van-col span="8" style="text-align: right;">
                    <span class="info3-order">¥{{order.salesPrice}}</span>
                  </van-col>

                </div>
                <div slot="price" v-if="order.specInfo!=null">
                  <span class="info-goods">规格：{{order.specInfo.replace(',',' ')}}</span>
                </div>
                <div slot="desc">
                </div>
                  <!--- 商品数量--->
             <div slot="footer">
              <div style="height:40px;margin-top:10px; "> 
                    <span class="info3-order" style="float:right">¥{{order.paymentPrice}}</span>
                    <span style="font-size:18px;padding-left:25px;float:right " >合计：</span>
                    <span style="font-size:18px;float:right">共{{order.quantity}}件商品 </span>
              </div>
             </div>
              </van-card>
            </div>
                    </div>
            <!--操作--->
        
                       <div v-if="item.type=='4'&&item.status>=4">
              <van-cell >
                      <!-- <van-button
                    color="#FF3C00"
                    style="border-radius: 20px; height:28px;float:right" @click="byGoods(item)">再次购买</van-button> -->
                     <van-button v-if="item.appraisesStatus=='0'"
                     style="border-radius: 20px; height:28px;float:right"  @click="OpenComment(item)" >评价</van-button>    
                <van-button
                    style="border-radius: 20px; height:28px;float:right">退款/售后</van-button>
                    
          
              </van-cell>
            </div>

          <!--   <div v-if="item.type=='4'&&item.status=='3'&& item.appraisesStatus=='0'">
              <van-cell >
                      <van-button
                     color="#FF3C00"
                     style="border-radius: 20px; height:28px;float:right"  @click="OpenComment(item)" >评价</van-button>
              </van-cell>
   
            </div>-->
    
            <!----->
          </div>
          </div>
       </van-list>
         </van-pull-refresh>
        </van-tab>

         <van-tab title="退款/售后">
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
        <div>
          <div
           class="order-div"
            v-for="(item,index) in  getfansOrderlister"
            :key="index"
          >
            <div>
              <!--店铺名--->
              <van-cell>
                <van-col span="16">{{item.shopInfo!=null?item.shopInfo.name:item.shopName}}</van-col>
                <van-col span="8"  class="status-word">{{item.statusDesc}}</van-col>
              </van-cell>
           </div>
            <!---商品信息--->
               <div v-for="(order,count) in  item.listOrderItem"
            :key="count" >
             <div @click="toDetail(item)">
              <van-card :num="order.quantity"  :thumb="order.picUrl">
                <div slot="title">
                  <van-col span="16">
                    <span>{{order.spuName}}</span>
                  </van-col>
                  <van-col span="8" style="text-align: right;">
                    <span class="info3-order">¥{{order.salesPrice}}</span>
                  </van-col>

                </div>
                <div slot="price" v-if="order.specInfo!=null">
                  <span class="info-goods">规格：{{order.specInfo.replace(',',' ')}}</span>
                </div>
                <div slot="desc">
                </div>
                  <!--- 商品数量--->
             <div slot="footer">
              <div style="height:40px;margin-top:10px; "> 
                    <span class="info3-order" style="float:right">¥{{order.paymentPrice}}</span>
                    <span style="font-size:18px;padding-left:25px;float:right " >合计：</span>
                    <span style="font-size:18px;float:right">共{{order.quantity}}件商品 </span>
              </div>
             </div>
              </van-card>
            </div>
                    </div>
            <!--操作--->
    
            <!----->
          </div>
          </div>
       </van-list>
         </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </div>

    <!-- <van-popup
   v-model="showShare"
   round
   position="bottom"
   :close-on-click-overlay="true"
   style="height: 100px"
   > 
        <div class="bottom-list">
         <div  @click="payGoods">
          <img src="../../assets/img/alipay-icon.png" alt="" />
          <p>支付宝</p>
        </div>
         
      </div> </van-popup> -->
    <!-- 订单信息 -->

    <order-pay
      :pay-show="showShare"
      :price="paymentPrice"
      :order-no="orderNo"
      :id="id"
      @close="showShare = false"
    ></order-pay>
  </div>
</template>
<script>
import { getorderManagement,cancelOrder } from "@/api/orderManagement/orderManagement";
import { receiveOrder } from "@/api/orderDetail";
import {Dialog, Toast } from "vant";
// import { submitAlipay } from '@/api/sureOrder/sureOrder'
import orderPay from "@/components/order-pay";
export default {
  name: "myOrder",
  components: {orderPay},
  data() {
    return {
      page: 1,//请求第几页
      pageSize: 6,//每页请求的数量
      total: 0,//总共的数据条数
      getfansOrderlister: [],
      loading: false,
      finished: true,
      active:-1,
      statusNum:-1,
      showShare:false,
      orderNo:'',
      paymentPrice: null,
      id: null,
      error: false,
      refreshing:false,
      isEmpty: false
    };
  },
  mounted() {
    this.active=this.$store.state.statusActive
    this.statusNum=this.active-1
        if(this.active==5)
        {
           this.statusNum=this.active+1
        }
    this.getOrderNewtype(this.statusNum);

  },
  methods: {
       getOrderNewtype(status){
      let params1 ={'current':this.page,'size':this.pageSize}
      let params = {}
      if(status>=0)
      {
          params={'status':status}
      }
       getorderManagement(Object.assign(params,params1)).then((res) => {
        if (res.data.code === 0) {
           if(res.data.data==null)
          {
             this.finished = true; 
             this.refreshing = false;
            return;
          }
          this.total = res.data.data.total;
          if (this.refreshing) {
            this.getfansOrderlister = [];
            this.refreshing = false;
          }
          if(res.data.data.records!=null&&res.data.data.records.length>0)
          {
          this.getfansOrderlister = this.getfansOrderlister.concat(res.data.data.records);
          }

          //for(var i=0;i<res.data.data.records.length;i++){
					//this.getfansOrderlister = this.getfansOrderlister.concat(res.data.data.records[i]);}

          this.loading = false; this.finished = false;
         if (res.data.data.records == null || res.data.data.records.length === 0) {
          // 加载结束
          this.finished = true; 
          return;
         }
          if (this.getfansOrderlister.length >= this.total) {
            this.finished = true;
          }
          //this.page++;
          this.isEmpty = this.getfansOrderlister.length == 0;
        }
        else{
          Toast(res.data.msg)
          if (this.refreshing) {
            this.getfansOrderlister = [];
            this.refreshing = false;
          }
          this.error = true;
          this.loading = false;
        }
      });     
    },
async   cancelOrder1(item){
    await Dialog.confirm({
         title: "标题",
         message: "是否确定取消订单？",
     })
         .then(() => {
            cancelOrder(item.id).then((res)=>
          {
          if (res.data.code===0) {
            Toast('取消订单成功');
           
           
            }
          }
          ).catch(() => {
            Toast('系统错误');
         });
         })
 },

    cancelOrder(item)
    {
        Dialog.confirm({
        title: "提示",
        message: "是否确定取消订单？"
        }).then(() => {

          cancelOrder(item.id).then((res)=>
          {
          if (res.data.code===0) {
            Toast('取消订单成功');
         if(this.active==0||this.active==-1)
         {
             item.status="5"
             item.statusDesc='已取消'
         }else{

             this.getfansOrderlister=[]
             this.getOrderNewtype(this.statusNum)
         }

            }
          }
          ).catch(() => {
            Toast('系统错误');
         });  
        })
        .catch(() => {
         
        });
        //this.getfansOrderlister(this.statusNum)
    },
      onLoad() {
      this.page++;
      this.getOrderNewtype(this.statusNum);
      //alert(this.statusNum)
    },
    onChange(event){
        {
       this.page=1;
       this.getfansOrderlister=[]
       this.active=event
  
       this.statusNum=event-1
       if(event==5)
       {
           this.statusNum=event+1
       }

      //alert(event)
       this.getOrderNewtype(this.statusNum)
      }
   },
   OpenPay(item)
   {
     console.log(item);
     this.showShare=true
     this.orderNo=item.orderNo
     this.paymentPrice=item.paymentPrice
     this.id=item.id
   },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.page = 0;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  //  payGoods()
  //  {
  //    this.showShare=false
  //     let alipay = {
  //           outTradeNo: this.orderNo,
  //           pamentPrice: '0.01'
  //         }

  //     this.alipay(alipay)
  //  },
  //  alipay(para) {
  //     submitAlipay(para).then((res) => {
  //       alert(this.orderNo)
  //       if (res.data.code === 0) {
                  
  //         let u = navigator.userAgent;
  //         let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //androi
  //         let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  //         if (isIOS) {
  //           window.webkit.messageHandlers.alipay.postMessage(res.data);
  //         }
  //         if (isAndroid) {
  //           window.android.alipay(res.data);
  //         }
  //       }
  //       else{
  //         Toast('下单失败')
  //       }
  //     });
  //   },
    toDetail(item) {
      if(item.type=='4')
      {
      this.$store.commit('setActive',this.active)
      this.$router.push({path: 'orders', query: { id:item.id }})
      }else
      {
        Toast('请到对应的APP查看订单详情')
      }
    },
    OpenComment(item)
    {
       this.$router.push({path: 'Commentshop', query: { Id:item.orderNo,name:item.shopInfo!= null?item.shopInfo.name:item.shopName }})
    },
    byGoods(item)
    {
        this.$router.push({path: 'detailsgoods', query: { id:item.listOrderItem[0].spuId,WoSession:this.$store.state.session }})
    },
      // 确认收货
    receiveOrder(item) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确认收货？",
        })
        .then(() => {
          // on confirm
          receiveOrder(item.id).then(() => {
          this.getfansOrderlister=[]
      
          // this.getOrderNewtype(this.statusNum)
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.van-card {
  position: relative;
  box-sizing: border-box;
  padding: 8px 16px;
  color: #323233;
  font-size: 12px;
  background-color: hsl(0deg 0% 100% / 90%);
}
.info3-order {
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: right;
  align-self: center;
}
.info-goods {
    color: #ccc5c5;
    font-size: 10px;
    font-family: PingFangSC-Regular;
    line-height: 5px;
    text-align: left;
    overflow-wrap: break-word;
}
.status-word
{
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: right;

}
.order-div
{
   margin-left:2%;
   margin-right:2%;
   border-radius: 5px;
   margin-top:10px; 
   box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
}
  .bottom-list {
    margin-top:10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
  .van-tabs--line .van-tabs__wrap {
    height: 44px;
    position: fixed;
    width: 100%;
}
.van-tabs__content
{
  padding-top:50px;
}
</style>
