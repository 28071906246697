<template>
  <div>
    <!-- 导航 -->
   <div style="margin:40px 0;">
   <van-tabs swipeable @change="onChange" :active="active" offset-top="46px" sticky>
  
   <van-tab title="全部">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="10" offset="6" class="word9">{{item.myShopStatus == 1? '待付款': item.myShopStatus == 2?'待发货': 
                    item.myShopStatus == 3? '退款/售后':  ''}}</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="item.quantity"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="16">
              <span>{{item.description}}</span>
             </van-col>
              <van-col span="6" offset="2">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共{{item.quantity}}件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
                <van-col span="6">{{item.sumMoney}}</van-col>
           </van-cell>
       </div>
       <!----->
             <!-- <div>
            <van-cell v-show="item.myShopStatus == 1?true:false">
               <van-col span="7" offset="10"> <van-button   plain style="border-radius: 20px; height:28px;width:94px">取消订单</van-button></van-col>
               <van-col span="7" > <van-button color="#FF3C00"  style="border-radius: 20px; height:28px;width:84px；">付款</van-button></van-col>
           </van-cell>
             <van-cell  v-show="item.myShopStatus == 2?true:false">
             <van-col span="7"  offset="17" > <van-button color="#FF3C00"  style="border-radius: 20px; height:28px;width:84px；">提醒发货</van-button></van-col>
           </van-cell>
             <van-cell v-show="item.myShopStatus == 3?true:false" >
             <van-col span="7"  offset="17"> <van-button color="#FF3C00" 
               @click="$router.push({name:'Commentshop',query: {'Id':item.id}})"
              style="border-radius: 20px; height:28px;width:84px">评价</van-button></van-col>
           </van-cell>

       </div>-->
       </div>
       </van-list>
      </van-pull-refresh>
   </van-tab>
   <van-tab title="待付款"> 
           <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
           <van-cell>
               <van-col span="8">{{item.shopName}}</van-col>
               <van-col span="8" offset="8" class="word9">待付款</van-col>
           </van-cell>
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="item.quantity"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="16">
              <span>{{item.description}}</span>
             </van-col>
              <van-col span="6" offset="2">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共{{item.quantity}}件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">{{item.sumMoney}}</van-col>
           </van-cell>
       </div>
       <!----->
       </div>
       </van-list>
           </van-pull-refresh>
       </van-tab>


    <van-tab title="待发货"> 
       
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
       </div>
    <!---商品信息--->
       <div>
        <van-card
         :num="item.quantity"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="16">
              <span>{{item.description}}</span>
             </van-col>
              <van-col span="6" offset="2">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共{{item.quantity}}件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">{{item.sumMoney}}</van-col>
           </van-cell>
       </div>
       <!----->
       </div>
       </van-list>
         </van-pull-refresh>
       </van-tab>

      <van-tab title="待收货"> 
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
       </div>
     <!---商品信息--->
       <div>
        <van-card
         :num="item.quantity"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="16">
              <span>{{item.description}}</span>
             </van-col>
              <van-col span="6" offset="2">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共{{item.quantity}}件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">{{item.sumMoney}}</van-col>
           </van-cell>
       </div>
       <!----->
       </div>
       </van-list>
        </van-pull-refresh>
       </van-tab>
         <van-tab title="已完成"> 
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
       </div>
    <!---商品信息--->
       <div>
        <van-card
          :num="item.quantity"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="16">
              <span>{{item.description}}</span>
             </van-col>
              <van-col span="6" offset="2">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共{{item.quantity}}件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">{{item.sumMoney}}</van-col>
           </van-cell>
       </div>
       <!----->
       </div>
       </van-list>
         </van-pull-refresh>
       </van-tab>
  <van-tab title="退款/售后"> 
         <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
           v-model="loading"
          :finished="finished"
          :immediate-check="false"
          finished-text="没有更多了"
          @load="onLoad"
         :offset="10"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          style="padding-bottom:50px;"
          v-show="!isEmpty"
         >
       <div style="margin-left:1%;margin-right:1%;border-radius: 5px;margin-top:5px" v-for="(item,index) in  getfansOrderlist" :key="index">
       <div>
           <!--店铺名--->
       </div>
    <!---商品信息--->
       <div>
        <van-card
          :num="item.quantity"
          price="10.00" :thumb="item.picUrl">
           <div slot="title" >
                  <van-col span="16">
              <span>{{item.description}}</span>
             </van-col>
              <van-col span="6" offset="2">
              <span class="info3-order">¥{{item.salesPrice}}</span>
             </van-col>
  
          </div>
         <div slot="price"> 
          <span class="info-goods" >规格：{{item.specInfo}}</span>
         </div>
        </van-card>
       </div>
       <!--- 商品数量--->
       <div>
            <van-cell>
               <van-col span="8" offset="5">共{{item.quantity}}件商品</van-col>
               <van-col span="4" offset="1">合计：</van-col>
               <van-col span="6">{{item.sumMoney}}</van-col>
           </van-cell>
       </div>
       <!----->
       </div>
       </van-list>
         </van-pull-refresh>
       </van-tab>
   </van-tabs>
    </div>
      <!-- 订单信息 -->
  </div>
</template>
<script>
import { getfansOrderNew } from  '@/api/fansOrderNew/fansOrderNew'
import { Toast } from "vant";
export default {
  name: "myOrder",
  data() {
    return {
      page: 1,//请求第几页
      pageSize: 20,//每页请求的数量
      total: 0,//总共的数据条数
      getfansOrderlist:[],
      active:-1,
      error: false,
      refreshing:false,
      isEmpty: false,
       loading: false,
      finished: true,
    };
  },
  mounted() {
    this.getfansOrderNewtype(0)
  },
  methods:{
     getfansOrderNewtype(status){
      let params1 ={'current':this.page,'size':this.pageSize}
      let params = {}

      if(status>0)
      {
          params={myShopStatus:status}
      }
       getfansOrderNew(Object.assign(params,params1)).then((res) => {
        if (res.data.code === 0) {
          if(res.data.data==null)
          {
             this.finished = true; 
             this.refreshing = false;
            return;
          }
          this.total = res.data.data.total;
          if (this.refreshing) {
            this.getfansOrderlister = [];
            this.refreshing = false;
          }
          if(res.data.data.records==null||res.data.data.records.length<=0)
          {
            this.finished = true; 
            this.refreshing = false;
            return;
          }
          this.getfansOrderlister = this.getfansOrderlister.concat(res.data.data.records);
          this.loading = false; this.finished = false;
         if (res.data.data.records == null || res.data.data.records.length === 0) {
          // 加载结束
          this.finished = true; 
          return;
         }
          if (this.getfansOrderlister.length >= this.total) {
            this.finished = true;
          }
          this.page++;
          this.isEmpty = this.getfansOrderlister.length == 0;
        }
        else{
          Toast(res.data.msg)
          if (this.refreshing) {
            this.getfansOrderlister = [];
            this.refreshing = false;
          }
          this.error = true;
          this.loading = false;
        }
      });     
    },
     //上滑加载
     onLoad() {
      //this.page++;
      //(this.page)
      this.getfansOrderNewtype(this.active);
    },
      onChange(event)
    {
      this.page=1;
      this.getfansOrderlist=[]
      this.getfansOrderNewtype(event)
      //alert(JSON.stringify(this.getfansOrderlist.length))
    },
     onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.page = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
  components: {},
};
</script>
<style scoped>
.van-card {
    position: relative;
    box-sizing: border-box;
    padding: 8px 16px;
    color: #323233;
    font-size: 12px;
    background-color: hsl(0deg 0% 100% / 90%);
}
.info3-order {
  margin-right:2px;
  overflow-wrap: break-word;
  color: rgba(168, 0, 0, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  line-height: 25px;
  text-align: left;
  align-self: center;
}
.word9 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 82, 0, 1);
  font-size: 14px;
  font-family: PingFangSC-Medium;
  line-height: 20px;
  text-align: right;
}
  .van-tabs--line .van-tabs__wrap {
    height: 44px;
    position: fixed;
    width: 100%;
}
.van-tabs__content
{
  padding-top:50px;
}
</style>
